import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Dimension: any;
  HexColor: any;
  JSON: any;
  Quality: any;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type Article = Entry &
  _Node & {
    __typename: 'Article';
    _id: Scalars['ID'];
    authorCollection: Maybe<ArticleAuthorCollection>;
    body: Maybe<ArticleBody>;
    category: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    embedMedia: Maybe<EmbedMedia>;
    factBoxCollection: Maybe<ArticleFactBoxCollection>;
    linkedFrom: Maybe<ArticleLinkingCollections>;
    mediaImageCollection: Maybe<ArticleMediaImageCollection>;
    mediaVideoCollection: Maybe<ArticleMediaVideoCollection>;
    preamble: Maybe<Scalars['String']>;
    publicationDate: Maybe<Scalars['DateTime']>;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<ArticleTagsCollection>;
    title: Maybe<Scalars['String']>;
    type: Maybe<Scalars['String']>;
  };

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleAuthorCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleCategoryArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleEmbedMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<EmbedMediaFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleMediaImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleMediaImageCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleMediaVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleMediaVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticlePreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticlePublicationDateArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/article) */
export type ArticleTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssembly = Entry &
  _Node & {
    __typename: 'ArticleAssembly';
    _id: Scalars['ID'];
    article: Maybe<Article>;
    contentfulMetadata: ContentfulMetadata;
    faqSection: Maybe<FaqSection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ArticleAssemblyLinkingCollections>;
    parentPage: Maybe<AssemblyLevel2>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblyArticleArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ArticleFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblyFaqSectionArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblyInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblyLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblyParentPageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyLevel2Filter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblySeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/articleAssembly) */
export type ArticleAssemblySlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type ArticleAssemblyCollection = {
  __typename: 'ArticleAssemblyCollection';
  items: Array<Maybe<ArticleAssembly>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ArticleAssemblyFilter = {
  AND: InputMaybe<Array<InputMaybe<ArticleAssemblyFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ArticleAssemblyFilter>>>;
  article: InputMaybe<CfArticleNestedFilter>;
  article_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSection: InputMaybe<CfFaqSectionNestedFilter>;
  faqSection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  parentPage: InputMaybe<CfAssemblyLevel2NestedFilter>;
  parentPage_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type ArticleAssemblyLinkingCollections = {
  __typename: 'ArticleAssemblyLinkingCollections';
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  entryCollection: Maybe<EntryCollection>;
};

export type ArticleAssemblyLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleAssemblyLinkingCollectionsAssemblyLevel0CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleAssemblyLinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleAssemblyLinkingCollectionsAssemblyLevel2CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleAssemblyLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ArticleAssemblyLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleAssemblyLinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleAssemblyOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticleAuthorCollection = {
  __typename: 'ArticleAuthorCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ArticleBody = {
  __typename: 'ArticleBody';
  json: Scalars['JSON'];
  links: ArticleBodyLinks;
};

export type ArticleBodyAssets = {
  __typename: 'ArticleBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ArticleBodyEntries = {
  __typename: 'ArticleBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ArticleBodyLinks = {
  __typename: 'ArticleBodyLinks';
  assets: ArticleBodyAssets;
  entries: ArticleBodyEntries;
  resources: ArticleBodyResources;
};

export type ArticleBodyResources = {
  __typename: 'ArticleBodyResources';
  block: Array<ArticleBodyResourcesBlock>;
  hyperlink: Array<ArticleBodyResourcesHyperlink>;
  inline: Array<ArticleBodyResourcesInline>;
};

export type ArticleBodyResourcesBlock = ResourceLink & {
  __typename: 'ArticleBodyResourcesBlock';
  sys: ResourceSys;
};

export type ArticleBodyResourcesHyperlink = ResourceLink & {
  __typename: 'ArticleBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type ArticleBodyResourcesInline = ResourceLink & {
  __typename: 'ArticleBodyResourcesInline';
  sys: ResourceSys;
};

export type ArticleCollection = {
  __typename: 'ArticleCollection';
  items: Array<Maybe<Article>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ArticleFactBoxCollection = {
  __typename: 'ArticleFactBoxCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ArticleFilter = {
  AND: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
  authorCollection_exists: InputMaybe<Scalars['Boolean']>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia: InputMaybe<CfEmbedMediaNestedFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  factBox: InputMaybe<CfFactBoxNestedFilter>;
  factBoxCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationDate: InputMaybe<Scalars['DateTime']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']>;
  publicationDate_gt: InputMaybe<Scalars['DateTime']>;
  publicationDate_gte: InputMaybe<Scalars['DateTime']>;
  publicationDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publicationDate_lt: InputMaybe<Scalars['DateTime']>;
  publicationDate_lte: InputMaybe<Scalars['DateTime']>;
  publicationDate_not: InputMaybe<Scalars['DateTime']>;
  publicationDate_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticleLinkingCollections = {
  __typename: 'ArticleLinkingCollections';
  articleAssemblyCollection: Maybe<ArticleAssemblyCollection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  entryCollection: Maybe<EntryCollection>;
};

export type ArticleLinkingCollectionsArticleAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsArticleAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsAssemblyLevel2CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ArticleLinkingCollectionsArticleAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticleMediaImageCollection = {
  __typename: 'ArticleMediaImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleMediaImageCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticleMediaVideoCollection = {
  __typename: 'ArticleMediaVideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleMediaVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ArticleTagsCollection = {
  __typename: 'ArticleTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReport = Entry &
  _Node & {
    __typename: 'AssemblyDamageReport';
    _id: Scalars['ID'];
    card: Maybe<Teaser>;
    contentfulMetadata: ContentfulMetadata;
    faqSection: Maybe<FaqSection>;
    heroBanner: Maybe<Teaser>;
    infoBox: Maybe<Resource>;
    linkedFrom: Maybe<AssemblyDamageReportLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportCardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportFaqSectionArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportInfoBoxArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ResourceFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyDamageReport) */
export type AssemblyDamageReportSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblyDamageReportCollection = {
  __typename: 'AssemblyDamageReportCollection';
  items: Array<Maybe<AssemblyDamageReport>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyDamageReportFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyDamageReportFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyDamageReportFilter>>>;
  card: InputMaybe<CfTeaserNestedFilter>;
  card_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSection: InputMaybe<CfFaqSectionNestedFilter>;
  faqSection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  infoBox: InputMaybe<CfResourceNestedFilter>;
  infoBox_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyDamageReportLinkingCollections = {
  __typename: 'AssemblyDamageReportLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyDamageReportLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyDamageReportOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0 = Entry &
  _Node & {
    __typename: 'AssemblyLevel0';
    _id: Scalars['ID'];
    bottomCardItemsCollection: Maybe<AssemblyLevel0BottomCardItemsCollection>;
    bottomCardsHeading: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    infoBox: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyLevel0LinkingCollections>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
    topCardItemsCollection: Maybe<AssemblyLevel0TopCardItemsCollection>;
    topCardsDescription: Maybe<Teaser>;
  };

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0BottomCardItemsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel0BottomCardItemsFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0BottomCardsHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0HeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0InfoBoxArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0InternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0LinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0SeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0TopCardItemsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel0TopCardItemsFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel0) */
export type AssemblyLevel0TopCardsDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

export type AssemblyLevel0BottomCardItemsCollection = {
  __typename: 'AssemblyLevel0BottomCardItemsCollection';
  items: Array<Maybe<AssemblyLevel0BottomCardItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel0BottomCardItemsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel0BottomCardItemsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel0BottomCardItemsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyLevel0BottomCardItemsItem =
  | ArticleAssembly
  | AssemblyLevel2
  | AssemblyLevel2Faq
  | Teaser;

export type AssemblyLevel0Collection = {
  __typename: 'AssemblyLevel0Collection';
  items: Array<Maybe<AssemblyLevel0>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel0Filter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel0Filter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel0Filter>>>;
  bottomCardItems: InputMaybe<CfbottomCardItemsMultiTypeNestedFilter>;
  bottomCardItemsCollection_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsHeading: InputMaybe<Scalars['String']>;
  bottomCardsHeading_contains: InputMaybe<Scalars['String']>;
  bottomCardsHeading_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomCardsHeading_not: InputMaybe<Scalars['String']>;
  bottomCardsHeading_not_contains: InputMaybe<Scalars['String']>;
  bottomCardsHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  infoBox: InputMaybe<CfTeaserNestedFilter>;
  infoBox_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  topCardItems: InputMaybe<CftopCardItemsMultiTypeNestedFilter>;
  topCardItemsCollection_exists: InputMaybe<Scalars['Boolean']>;
  topCardsDescription: InputMaybe<CfTeaserNestedFilter>;
  topCardsDescription_exists: InputMaybe<Scalars['Boolean']>;
};

export type AssemblyLevel0LinkingCollections = {
  __typename: 'AssemblyLevel0LinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyLevel0LinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyLevel0Order {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyLevel0TopCardItemsCollection = {
  __typename: 'AssemblyLevel0TopCardItemsCollection';
  items: Array<Maybe<AssemblyLevel0TopCardItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel0TopCardItemsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel0TopCardItemsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel0TopCardItemsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyLevel0TopCardItemsItem =
  | ArticleAssembly
  | AssemblyLevel2
  | AssemblyLevel2Faq
  | Teaser;

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2 = Entry &
  _Node & {
    __typename: 'AssemblyLevel2';
    _id: Scalars['ID'];
    article: Maybe<Article>;
    cardsCollection: Maybe<AssemblyLevel2CardsCollection>;
    cardsTitle: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    faqSectionsCollection: Maybe<AssemblyLevel2FaqSectionsCollection>;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyLevel2LinkingCollections>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2ArticleArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ArticleFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2CardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel2CardsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2CardsTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2FaqSectionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel2FaqSectionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2HeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2InternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2LinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2SeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2) */
export type AssemblyLevel2SlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblyLevel2CardsCollection = {
  __typename: 'AssemblyLevel2CardsCollection';
  items: Array<Maybe<AssemblyLevel2CardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel2CardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel2CardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel2CardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyLevel2CardsItem = ArticleAssembly | AssemblyLevel2 | AssemblyLevel2Faq | Teaser;

export type AssemblyLevel2Collection = {
  __typename: 'AssemblyLevel2Collection';
  items: Array<Maybe<AssemblyLevel2>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Assembly for level 2 FAQ page. [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2Faq) */
export type AssemblyLevel2Faq = Entry &
  _Node & {
    __typename: 'AssemblyLevel2Faq';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    faqSectionsCollection: Maybe<AssemblyLevel2FaqFaqSectionsCollection>;
    heroBanner: Maybe<Teaser>;
    linkedFrom: Maybe<AssemblyLevel2FaqLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Assembly for level 2 FAQ page. [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2Faq) */
export type AssemblyLevel2FaqFaqSectionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel2FaqFaqSectionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** Assembly for level 2 FAQ page. [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2Faq) */
export type AssemblyLevel2FaqHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Assembly for level 2 FAQ page. [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2Faq) */
export type AssemblyLevel2FaqLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Assembly for level 2 FAQ page. [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2Faq) */
export type AssemblyLevel2FaqSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** Assembly for level 2 FAQ page. [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/assemblyLevel2Faq) */
export type AssemblyLevel2FaqSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblyLevel2FaqCollection = {
  __typename: 'AssemblyLevel2FaqCollection';
  items: Array<Maybe<AssemblyLevel2Faq>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel2FaqFaqSectionsCollection = {
  __typename: 'AssemblyLevel2FaqFaqSectionsCollection';
  items: Array<Maybe<FaqSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyLevel2FaqFaqSectionsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyLevel2FaqFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel2FaqFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel2FaqFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSections: InputMaybe<CfFaqSectionNestedFilter>;
  faqSectionsCollection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyLevel2FaqLinkingCollections = {
  __typename: 'AssemblyLevel2FaqLinkingCollections';
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyLevel2FaqLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel2FaqLinkingCollectionsAssemblyLevel0CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel2FaqLinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel2FaqLinkingCollectionsAssemblyLevel2CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel2FaqLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyLevel2FaqLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssemblyLevel2FaqLinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssemblyLevel2FaqOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyLevel2FaqSectionsCollection = {
  __typename: 'AssemblyLevel2FaqSectionsCollection';
  items: Array<Maybe<FaqSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyLevel2FaqSectionsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyLevel2Filter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel2Filter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel2Filter>>>;
  article: InputMaybe<CfArticleNestedFilter>;
  article_exists: InputMaybe<Scalars['Boolean']>;
  cards: InputMaybe<CfcardsMultiTypeNestedFilter>;
  cardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  cardsTitle: InputMaybe<Scalars['String']>;
  cardsTitle_contains: InputMaybe<Scalars['String']>;
  cardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  cardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cardsTitle_not: InputMaybe<Scalars['String']>;
  cardsTitle_not_contains: InputMaybe<Scalars['String']>;
  cardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSections: InputMaybe<CfFaqSectionNestedFilter>;
  faqSectionsCollection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyLevel2LinkingCollections = {
  __typename: 'AssemblyLevel2LinkingCollections';
  articleAssemblyCollection: Maybe<ArticleAssemblyCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyLevel2LinkingCollectionsArticleAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel2LinkingCollectionsArticleAssemblyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel2LinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel2LinkingCollectionsAssemblyLevel0CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel2LinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel2LinkingCollectionsAssemblyLevel2CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel2LinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyLevel2LinkingCollectionsArticleAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssemblyLevel2LinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssemblyLevel2LinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssemblyLevel2Order {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename: 'Asset';
  contentType: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  description: Maybe<Scalars['String']>;
  fileName: Maybe<Scalars['String']>;
  height: Maybe<Scalars['Int']>;
  linkedFrom: Maybe<AssetLinkingCollections>;
  size: Maybe<Scalars['Int']>;
  sys: Sys;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Int']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale: InputMaybe<Scalars['String']>;
  transform: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
  __typename: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType: InputMaybe<Scalars['String']>;
  contentType_contains: InputMaybe<Scalars['String']>;
  contentType_exists: InputMaybe<Scalars['Boolean']>;
  contentType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not: InputMaybe<Scalars['String']>;
  contentType_not_contains: InputMaybe<Scalars['String']>;
  contentType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName: InputMaybe<Scalars['String']>;
  fileName_contains: InputMaybe<Scalars['String']>;
  fileName_exists: InputMaybe<Scalars['Boolean']>;
  fileName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not: InputMaybe<Scalars['String']>;
  fileName_not_contains: InputMaybe<Scalars['String']>;
  fileName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height: InputMaybe<Scalars['Int']>;
  height_exists: InputMaybe<Scalars['Boolean']>;
  height_gt: InputMaybe<Scalars['Int']>;
  height_gte: InputMaybe<Scalars['Int']>;
  height_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt: InputMaybe<Scalars['Int']>;
  height_lte: InputMaybe<Scalars['Int']>;
  height_not: InputMaybe<Scalars['Int']>;
  height_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size: InputMaybe<Scalars['Int']>;
  size_exists: InputMaybe<Scalars['Boolean']>;
  size_gt: InputMaybe<Scalars['Int']>;
  size_gte: InputMaybe<Scalars['Int']>;
  size_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt: InputMaybe<Scalars['Int']>;
  size_lte: InputMaybe<Scalars['Int']>;
  size_not: InputMaybe<Scalars['Int']>;
  size_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url: InputMaybe<Scalars['String']>;
  url_contains: InputMaybe<Scalars['String']>;
  url_exists: InputMaybe<Scalars['Boolean']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not: InputMaybe<Scalars['String']>;
  url_not_contains: InputMaybe<Scalars['String']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width: InputMaybe<Scalars['Int']>;
  width_exists: InputMaybe<Scalars['Boolean']>;
  width_gt: InputMaybe<Scalars['Int']>;
  width_gte: InputMaybe<Scalars['Int']>;
  width_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt: InputMaybe<Scalars['Int']>;
  width_lte: InputMaybe<Scalars['Int']>;
  width_not: InputMaybe<Scalars['Int']>;
  width_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  __typename: 'AssetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  imageCollection: Maybe<ImageCollection>;
  videoCollection: Maybe<VideoCollection>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type Campaign = Entry &
  _Node & {
    __typename: 'Campaign';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    faqCollection: Maybe<CampaignFaqCollection>;
    focusContent: Maybe<Teaser>;
    generalInformationCollection: Maybe<CampaignGeneralInformationCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<CampaignLinkingCollections>;
    mainContentCollection: Maybe<CampaignMainContentCollection>;
    mediaImageCollection: Maybe<CampaignMediaImageCollection>;
    mediaVideoCollection: Maybe<CampaignMediaVideoCollection>;
    preamble: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
    type: Maybe<Scalars['String']>;
  };

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignFaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignFocusContentArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignGeneralInformationCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignGeneralInformationCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignMainContentCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignMediaImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignMediaImageCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignMediaVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignMediaVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/campaign) */
export type CampaignTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type CampaignCollection = {
  __typename: 'CampaignCollection';
  items: Array<Maybe<Campaign>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignFaqCollection = {
  __typename: 'CampaignFaqCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignFilter = {
  AND: InputMaybe<Array<InputMaybe<CampaignFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CampaignFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqCollection_exists: InputMaybe<Scalars['Boolean']>;
  focusContent: InputMaybe<CfTeaserNestedFilter>;
  focusContent_exists: InputMaybe<Scalars['Boolean']>;
  generalInformation: InputMaybe<CfFactBoxNestedFilter>;
  generalInformationCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mainContentCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CampaignGeneralInformationCollection = {
  __typename: 'CampaignGeneralInformationCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignGeneralInformationCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CampaignLinkingCollections = {
  __typename: 'CampaignLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type CampaignLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CampaignMainContentCollection = {
  __typename: 'CampaignMainContentCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignMediaImageCollection = {
  __typename: 'CampaignMediaImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignMediaImageCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CampaignMediaVideoCollection = {
  __typename: 'CampaignMediaVideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignMediaVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CampaignOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ContentfulMetadata = {
  __typename: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename: 'ContentfulTag';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButton = Entry &
  _Node & {
    __typename: 'CtaButton';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    ctaText: Maybe<Scalars['String']>;
    ctaType: Maybe<Scalars['String']>;
    imageTrigger: Maybe<Image>;
    internalName: Maybe<Scalars['String']>;
    linkTrigger: Maybe<Scalars['String']>;
    linkedFrom: Maybe<CtaButtonLinkingCollections>;
    sys: Sys;
    videoTrigger: Maybe<Video>;
  };

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonCtaTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonCtaTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonImageTriggerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonLinkTriggerArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/ctaButton) */
export type CtaButtonVideoTriggerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoFilter>;
};

export type CtaButtonCollection = {
  __typename: 'CtaButtonCollection';
  items: Array<Maybe<CtaButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CtaButtonFilter = {
  AND: InputMaybe<Array<InputMaybe<CtaButtonFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CtaButtonFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  ctaText: InputMaybe<Scalars['String']>;
  ctaText_contains: InputMaybe<Scalars['String']>;
  ctaText_exists: InputMaybe<Scalars['Boolean']>;
  ctaText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaText_not: InputMaybe<Scalars['String']>;
  ctaText_not_contains: InputMaybe<Scalars['String']>;
  ctaText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType: InputMaybe<Scalars['String']>;
  ctaType_contains: InputMaybe<Scalars['String']>;
  ctaType_exists: InputMaybe<Scalars['Boolean']>;
  ctaType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType_not: InputMaybe<Scalars['String']>;
  ctaType_not_contains: InputMaybe<Scalars['String']>;
  ctaType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageTrigger: InputMaybe<CfImageNestedFilter>;
  imageTrigger_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger: InputMaybe<Scalars['String']>;
  linkTrigger_contains: InputMaybe<Scalars['String']>;
  linkTrigger_exists: InputMaybe<Scalars['Boolean']>;
  linkTrigger_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger_not: InputMaybe<Scalars['String']>;
  linkTrigger_not_contains: InputMaybe<Scalars['String']>;
  linkTrigger_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  videoTrigger: InputMaybe<CfVideoNestedFilter>;
  videoTrigger_exists: InputMaybe<Scalars['Boolean']>;
};

export type CtaButtonLinkingCollections = {
  __typename: 'CtaButtonLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type CtaButtonLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum CtaButtonLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CtaButtonLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CtaButtonOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/embedMedia) */
export type EmbedMedia = Entry &
  _Node & {
    __typename: 'EmbedMedia';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<EmbedMediaLinkingCollections>;
    sys: Sys;
    url: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/embedMedia) */
export type EmbedMediaInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/embedMedia) */
export type EmbedMediaLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/embedMedia) */
export type EmbedMediaUrlArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type EmbedMediaCollection = {
  __typename: 'EmbedMediaCollection';
  items: Array<Maybe<EmbedMedia>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EmbedMediaFilter = {
  AND: InputMaybe<Array<InputMaybe<EmbedMediaFilter>>>;
  OR: InputMaybe<Array<InputMaybe<EmbedMediaFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  url: InputMaybe<Scalars['String']>;
  url_contains: InputMaybe<Scalars['String']>;
  url_exists: InputMaybe<Scalars['Boolean']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not: InputMaybe<Scalars['String']>;
  url_not_contains: InputMaybe<Scalars['String']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EmbedMediaLinkingCollections = {
  __typename: 'EmbedMediaLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  entryCollection: Maybe<EntryCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type EmbedMediaLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EmbedMediaLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type EmbedMediaLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type EmbedMediaLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EmbedMediaLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum EmbedMediaLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum EmbedMediaLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum EmbedMediaOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBox = Entry &
  _Node & {
    __typename: 'FactBox';
    _id: Scalars['ID'];
    body: Maybe<FactBoxBody>;
    callToActionCollection: Maybe<FactBoxCallToActionCollection>;
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<FactBoxLinkingCollections>;
    mediaAssetsCollection: Maybe<AssetCollection>;
    mediaImage: Maybe<Image>;
    mediaVideo: Maybe<Video>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<FactBoxTagsCollection>;
    title: Maybe<Scalars['String']>;
  };

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxCallToActionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxCallToActionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxMediaAssetsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxMediaImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxMediaVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBox) */
export type FactBoxTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FactBoxBody = {
  __typename: 'FactBoxBody';
  json: Scalars['JSON'];
  links: FactBoxBodyLinks;
};

export type FactBoxBodyAssets = {
  __typename: 'FactBoxBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FactBoxBodyEntries = {
  __typename: 'FactBoxBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FactBoxBodyLinks = {
  __typename: 'FactBoxBodyLinks';
  assets: FactBoxBodyAssets;
  entries: FactBoxBodyEntries;
  resources: FactBoxBodyResources;
};

export type FactBoxBodyResources = {
  __typename: 'FactBoxBodyResources';
  block: Array<FactBoxBodyResourcesBlock>;
  hyperlink: Array<FactBoxBodyResourcesHyperlink>;
  inline: Array<FactBoxBodyResourcesInline>;
};

export type FactBoxBodyResourcesBlock = ResourceLink & {
  __typename: 'FactBoxBodyResourcesBlock';
  sys: ResourceSys;
};

export type FactBoxBodyResourcesHyperlink = ResourceLink & {
  __typename: 'FactBoxBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type FactBoxBodyResourcesInline = ResourceLink & {
  __typename: 'FactBoxBodyResourcesInline';
  sys: ResourceSys;
};

export type FactBoxCallToActionCollection = {
  __typename: 'FactBoxCallToActionCollection';
  items: Array<Maybe<CtaButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FactBoxCallToActionCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FactBoxCollection = {
  __typename: 'FactBoxCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FactBoxFilter = {
  AND: InputMaybe<Array<InputMaybe<FactBoxFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FactBoxFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  callToAction: InputMaybe<CfCtaButtonNestedFilter>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaAssetsCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImage_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FactBoxLinkingCollections = {
  __typename: 'FactBoxLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxWrapperCollection: Maybe<FactBoxWrapperCollection>;
};

export type FactBoxLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsFactBoxWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsFactBoxWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FactBoxLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum FactBoxLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum FactBoxLinkingCollectionsFactBoxWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FactBoxOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FactBoxTagsCollection = {
  __typename: 'FactBoxTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FactBoxTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBoxWrapper) */
export type FactBoxWrapper = Entry &
  _Node & {
    __typename: 'FactBoxWrapper';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    factBoxesCollection: Maybe<FactBoxWrapperFactBoxesCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<FactBoxWrapperLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBoxWrapper) */
export type FactBoxWrapperFactBoxesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxWrapperFactBoxesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBoxWrapper) */
export type FactBoxWrapperInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBoxWrapper) */
export type FactBoxWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/factBoxWrapper) */
export type FactBoxWrapperReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FactBoxWrapperCollection = {
  __typename: 'FactBoxWrapperCollection';
  items: Array<Maybe<FactBoxWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FactBoxWrapperFactBoxesCollection = {
  __typename: 'FactBoxWrapperFactBoxesCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FactBoxWrapperFactBoxesCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FactBoxWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<FactBoxWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FactBoxWrapperFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  factBoxes: InputMaybe<CfFactBoxNestedFilter>;
  factBoxesCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type FactBoxWrapperLinkingCollections = {
  __typename: 'FactBoxWrapperLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type FactBoxWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FactBoxWrapperOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqItem) */
export type FaqItem = Entry &
  _Node & {
    __typename: 'FaqItem';
    _id: Scalars['ID'];
    answer: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom: Maybe<FaqItemLinkingCollections>;
    question: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqItem) */
export type FaqItemAnswerArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqItem) */
export type FaqItemLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqItem) */
export type FaqItemQuestionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FaqItemCollection = {
  __typename: 'FaqItemCollection';
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqItemFilter = {
  AND: InputMaybe<Array<InputMaybe<FaqItemFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FaqItemFilter>>>;
  answer: InputMaybe<Scalars['String']>;
  answer_contains: InputMaybe<Scalars['String']>;
  answer_exists: InputMaybe<Scalars['Boolean']>;
  answer_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  answer_not: InputMaybe<Scalars['String']>;
  answer_not_contains: InputMaybe<Scalars['String']>;
  answer_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  question: InputMaybe<Scalars['String']>;
  question_contains: InputMaybe<Scalars['String']>;
  question_exists: InputMaybe<Scalars['Boolean']>;
  question_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  question_not: InputMaybe<Scalars['String']>;
  question_not_contains: InputMaybe<Scalars['String']>;
  question_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type FaqItemLinkingCollections = {
  __typename: 'FaqItemLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  faqSectionCollection: Maybe<FaqSectionCollection>;
};

export type FaqItemLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqItemLinkingCollectionsFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqItemLinkingCollectionsFaqSectionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FaqItemLinkingCollectionsFaqSectionCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqItemOrder {
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqSection) */
export type FaqSection = Entry &
  _Node & {
    __typename: 'FaqSection';
    _id: Scalars['ID'];
    backgroundTitle: Maybe<Image>;
    contentfulMetadata: ContentfulMetadata;
    faqSectionCollection: Maybe<FaqSectionFaqSectionCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<FaqSectionLinkingCollections>;
    sectionTitle: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqSection) */
export type FaqSectionBackgroundTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqSection) */
export type FaqSectionFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionFaqSectionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqSection) */
export type FaqSectionInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqSection) */
export type FaqSectionLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/faqSection) */
export type FaqSectionSectionTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FaqSectionCollection = {
  __typename: 'FaqSectionCollection';
  items: Array<Maybe<FaqSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqSectionFaqSectionCollection = {
  __typename: 'FaqSectionFaqSectionCollection';
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqSectionFaqSectionCollectionOrder {
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqSectionFilter = {
  AND: InputMaybe<Array<InputMaybe<FaqSectionFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FaqSectionFilter>>>;
  backgroundTitle: InputMaybe<CfImageNestedFilter>;
  backgroundTitle_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSection: InputMaybe<CfFaqItemNestedFilter>;
  faqSectionCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle: InputMaybe<Scalars['String']>;
  sectionTitle_contains: InputMaybe<Scalars['String']>;
  sectionTitle_exists: InputMaybe<Scalars['Boolean']>;
  sectionTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle_not: InputMaybe<Scalars['String']>;
  sectionTitle_not_contains: InputMaybe<Scalars['String']>;
  sectionTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type FaqSectionLinkingCollections = {
  __typename: 'FaqSectionLinkingCollections';
  articleAssemblyCollection: Maybe<ArticleAssemblyCollection>;
  assemblyDamageReportCollection: Maybe<AssemblyDamageReportCollection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  assemblyLevel2FaqCollection: Maybe<AssemblyLevel2FaqCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type FaqSectionLinkingCollectionsArticleAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionLinkingCollectionsArticleAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqSectionLinkingCollectionsAssemblyDamageReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<FaqSectionLinkingCollectionsAssemblyDamageReportCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqSectionLinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionLinkingCollectionsAssemblyLevel2CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqSectionLinkingCollectionsAssemblyLevel2FaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<FaqSectionLinkingCollectionsAssemblyLevel2FaqCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqSectionLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FaqSectionLinkingCollectionsArticleAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqSectionLinkingCollectionsAssemblyDamageReportCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqSectionLinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqSectionLinkingCollectionsAssemblyLevel2FaqCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqSectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type Image = Entry &
  _Node & {
    __typename: 'Image';
    _id: Scalars['ID'];
    altText: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    copyrightImg: Maybe<Asset>;
    copyrightText: Maybe<Scalars['String']>;
    focusArea: Maybe<Scalars['String']>;
    hyperlink: Maybe<Scalars['String']> | null;
    image: Maybe<Asset>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ImageLinkingCollections>;
    sys: Sys;
  };

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageAltTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageCopyrightImgArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageCopyrightTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageFocusAreaArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageHyperlinkArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/image) */
export type ImageLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ImageCollection = {
  __typename: 'ImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageFilter = {
  AND: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  altText: InputMaybe<Scalars['String']>;
  altText_contains: InputMaybe<Scalars['String']>;
  altText_exists: InputMaybe<Scalars['Boolean']>;
  altText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  altText_not: InputMaybe<Scalars['String']>;
  altText_not_contains: InputMaybe<Scalars['String']>;
  altText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightImg_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea: InputMaybe<Scalars['String']>;
  focusArea_contains: InputMaybe<Scalars['String']>;
  focusArea_exists: InputMaybe<Scalars['Boolean']>;
  focusArea_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea_not: InputMaybe<Scalars['String']>;
  focusArea_not_contains: InputMaybe<Scalars['String']>;
  focusArea_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink: InputMaybe<Scalars['String']>;
  hyperlink_contains: InputMaybe<Scalars['String']>;
  hyperlink_exists: InputMaybe<Scalars['Boolean']>;
  hyperlink_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink_not: InputMaybe<Scalars['String']>;
  hyperlink_not_contains: InputMaybe<Scalars['String']>;
  hyperlink_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export type ImageLinkingCollections = {
  __typename: 'ImageLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  ctaButtonCollection: Maybe<CtaButtonCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  faqSectionCollection: Maybe<FaqSectionCollection>;
  seoCollection: Maybe<SeoCollection>;
  teaserCollection: Maybe<TeaserCollection>;
  videoCollection: Maybe<VideoCollection>;
};

export type ImageLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsCtaButtonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsCtaButtonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsFaqSectionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsSeoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsSeoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ImageLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ImageLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ImageLinkingCollectionsCtaButtonCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ImageLinkingCollectionsFaqSectionCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageLinkingCollectionsSeoCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SocialMediaSiteNameAsc = 'socialMediaSiteName_ASC',
  SocialMediaSiteNameDesc = 'socialMediaSiteName_DESC',
  SocialMediaTitleAsc = 'socialMediaTitle_ASC',
  SocialMediaTitleDesc = 'socialMediaTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  XRobotsTagAsc = 'xRobotsTag_ASC',
  XRobotsTagDesc = 'xRobotsTag_DESC',
}

export enum ImageLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ImageLinkingCollectionsVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width: InputMaybe<Scalars['Dimension']>;
};

export type Query = {
  __typename: 'Query';
  _node: Maybe<_Node>;
  article: Maybe<Article>;
  articleAssembly: Maybe<ArticleAssembly>;
  articleAssemblyCollection: Maybe<ArticleAssemblyCollection>;
  articleCollection: Maybe<ArticleCollection>;
  assemblyDamageReport: Maybe<AssemblyDamageReport>;
  assemblyDamageReportCollection: Maybe<AssemblyDamageReportCollection>;
  assemblyLevel0: Maybe<AssemblyLevel0>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel2: Maybe<AssemblyLevel2>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  assemblyLevel2Faq: Maybe<AssemblyLevel2Faq>;
  assemblyLevel2FaqCollection: Maybe<AssemblyLevel2FaqCollection>;
  asset: Maybe<Asset>;
  assetCollection: Maybe<AssetCollection>;
  campaign: Maybe<Campaign>;
  campaignCollection: Maybe<CampaignCollection>;
  ctaButton: Maybe<CtaButton>;
  ctaButtonCollection: Maybe<CtaButtonCollection>;
  embedMedia: Maybe<EmbedMedia>;
  embedMediaCollection: Maybe<EmbedMediaCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBox: Maybe<FactBox>;
  factBoxCollection: Maybe<FactBoxCollection>;
  factBoxWrapper: Maybe<FactBoxWrapper>;
  factBoxWrapperCollection: Maybe<FactBoxWrapperCollection>;
  faqItem: Maybe<FaqItem>;
  faqItemCollection: Maybe<FaqItemCollection>;
  faqSection: Maybe<FaqSection>;
  faqSectionCollection: Maybe<FaqSectionCollection>;
  image: Maybe<Image>;
  imageCollection: Maybe<ImageCollection>;
  resource: Maybe<Resource>;
  resourceCollection: Maybe<ResourceCollection>;
  resourceSet: Maybe<ResourceSet>;
  resourceSetCollection: Maybe<ResourceSetCollection>;
  seo: Maybe<Seo>;
  seoCollection: Maybe<SeoCollection>;
  table: Maybe<Table>;
  tableCollection: Maybe<TableCollection>;
  tag: Maybe<Tag>;
  tagCollection: Maybe<TagCollection>;
  teaser: Maybe<Teaser>;
  teaserCollection: Maybe<TeaserCollection>;
  video: Maybe<Video>;
  videoCollection: Maybe<VideoCollection>;
};

export type Query_NodeArgs = {
  id: Scalars['ID'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryArticleArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryArticleAssemblyArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryArticleAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleAssemblyOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleAssemblyFilter>;
};

export type QueryArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleFilter>;
};

export type QueryAssemblyDamageReportArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyDamageReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyDamageReportOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyDamageReportFilter>;
};

export type QueryAssemblyLevel0Args = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel0Order>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel0Filter>;
};

export type QueryAssemblyLevel2Args = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel2Order>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel2Filter>;
};

export type QueryAssemblyLevel2FaqArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyLevel2FaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel2FaqOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel2FaqFilter>;
};

export type QueryAssetArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssetCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssetFilter>;
};

export type QueryCampaignArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CampaignFilter>;
};

export type QueryCtaButtonArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryCtaButtonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CtaButtonFilter>;
};

export type QueryEmbedMediaArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryEmbedMediaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EmbedMediaOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<EmbedMediaFilter>;
};

export type QueryEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<EntryFilter>;
};

export type QueryFactBoxArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

export type QueryFactBoxWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFactBoxWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxWrapperFilter>;
};

export type QueryFaqItemArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqItemCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqItemOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqItemFilter>;
};

export type QueryFaqSectionArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqSectionFilter>;
};

export type QueryImageArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

export type QueryResourceArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryResourceCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ResourceFilter>;
};

export type QueryResourceSetArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryResourceSetCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceSetOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ResourceSetFilter>;
};

export type QuerySeoArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QuerySeoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SeoFilter>;
};

export type QueryTableArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTableCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TableOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TableFilter>;
};

export type QueryTagArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTagCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

export type QueryTeaserArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

export type QueryVideoArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resource) */
export type Resource = Entry &
  _Node & {
    __typename: 'Resource';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    key: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ResourceLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    value: Maybe<Scalars['String']>;
  };

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resource) */
export type ResourceInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resource) */
export type ResourceKeyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resource) */
export type ResourceLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resource) */
export type ResourceReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resource) */
export type ResourceValueArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type ResourceCollection = {
  __typename: 'ResourceCollection';
  items: Array<Maybe<Resource>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResourceFilter = {
  AND: InputMaybe<Array<InputMaybe<ResourceFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ResourceFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key: InputMaybe<Scalars['String']>;
  key_contains: InputMaybe<Scalars['String']>;
  key_exists: InputMaybe<Scalars['Boolean']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not: InputMaybe<Scalars['String']>;
  key_not_contains: InputMaybe<Scalars['String']>;
  key_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceLinkingCollections = {
  __typename: 'ResourceLinkingCollections';
  assemblyDamageReportCollection: Maybe<AssemblyDamageReportCollection>;
  entryCollection: Maybe<EntryCollection>;
  resourceSetCollection: Maybe<ResourceSetCollection>;
};

export type ResourceLinkingCollectionsAssemblyDamageReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ResourceLinkingCollectionsAssemblyDamageReportCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ResourceLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ResourceLinkingCollectionsResourceSetCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceLinkingCollectionsResourceSetCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ResourceLinkingCollectionsAssemblyDamageReportCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ResourceLinkingCollectionsResourceSetCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ResourceOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resourceSet) */
export type ResourceSet = Entry &
  _Node & {
    __typename: 'ResourceSet';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ResourceSetLinkingCollections>;
    name: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    resourcesCollection: Maybe<ResourceSetResourcesCollection>;
    sys: Sys;
  };

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resourceSet) */
export type ResourceSetInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resourceSet) */
export type ResourceSetLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resourceSet) */
export type ResourceSetNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resourceSet) */
export type ResourceSetReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/resourceSet) */
export type ResourceSetResourcesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceSetResourcesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ResourceFilter>;
};

export type ResourceSetCollection = {
  __typename: 'ResourceSetCollection';
  items: Array<Maybe<ResourceSet>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResourceSetFilter = {
  AND: InputMaybe<Array<InputMaybe<ResourceSetFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ResourceSetFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resources: InputMaybe<CfResourceNestedFilter>;
  resourcesCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type ResourceSetLinkingCollections = {
  __typename: 'ResourceSetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type ResourceSetLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ResourceSetOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ResourceSetResourcesCollection = {
  __typename: 'ResourceSetResourcesCollection';
  items: Array<Maybe<Resource>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ResourceSetResourcesCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ResourceSys = {
  __typename: 'ResourceSys';
  linkType: Scalars['String'];
  urn: Scalars['String'];
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type Seo = Entry &
  _Node & {
    __typename: 'Seo';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    keywords: Maybe<Array<Maybe<Scalars['String']>>>;
    linkedFrom: Maybe<SeoLinkingCollections>;
    noFollow: Maybe<Scalars['Boolean']>;
    noIndex: Maybe<Scalars['Boolean']>;
    readableId: Maybe<Scalars['String']>;
    socialMediaDescription: Maybe<Scalars['String']>;
    socialMediaImage: Maybe<Image>;
    socialMediaSiteName: Maybe<Scalars['String']>;
    socialMediaTitle: Maybe<Scalars['String']>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
    xRobotsTag: Maybe<Scalars['DateTime']>;
  };

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoKeywordsArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoNoFollowArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoNoIndexArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoSocialMediaDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoSocialMediaImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoSocialMediaSiteNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoSocialMediaTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/seo) */
export type SeoXRobotsTagArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type SeoCollection = {
  __typename: 'SeoCollection';
  items: Array<Maybe<Seo>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoFilter = {
  AND: InputMaybe<Array<InputMaybe<SeoFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SeoFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists: InputMaybe<Scalars['Boolean']>;
  no_follow: InputMaybe<Scalars['Boolean']>;
  no_follow_exists: InputMaybe<Scalars['Boolean']>;
  no_follow_not: InputMaybe<Scalars['Boolean']>;
  no_index: InputMaybe<Scalars['Boolean']>;
  no_index_exists: InputMaybe<Scalars['Boolean']>;
  no_index_not: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription: InputMaybe<Scalars['String']>;
  socialMediaDescription_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaDescription_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription_not: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaImage: InputMaybe<CfImageNestedFilter>;
  socialMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName: InputMaybe<Scalars['String']>;
  socialMediaSiteName_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaSiteName_not: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle: InputMaybe<Scalars['String']>;
  socialMediaTitle_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle_not: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xRobotsTag: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_exists: InputMaybe<Scalars['Boolean']>;
  xRobotsTag_gt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_gte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  xRobotsTag_lt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_lte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type SeoLinkingCollections = {
  __typename: 'SeoLinkingCollections';
  articleAssemblyCollection: Maybe<ArticleAssemblyCollection>;
  articleCollection: Maybe<ArticleCollection>;
  assemblyDamageReportCollection: Maybe<AssemblyDamageReportCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  assemblyLevel2FaqCollection: Maybe<AssemblyLevel2FaqCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type SeoLinkingCollectionsArticleAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsArticleAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyDamageReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyDamageReportCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyLevel0CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyLevel2CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyLevel2FaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyLevel2FaqCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum SeoLinkingCollectionsArticleAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum SeoLinkingCollectionsAssemblyDamageReportCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyLevel2FaqCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum SeoOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SocialMediaSiteNameAsc = 'socialMediaSiteName_ASC',
  SocialMediaSiteNameDesc = 'socialMediaSiteName_DESC',
  SocialMediaTitleAsc = 'socialMediaTitle_ASC',
  SocialMediaTitleDesc = 'socialMediaTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  XRobotsTagAsc = 'xRobotsTag_ASC',
  XRobotsTagDesc = 'xRobotsTag_DESC',
}

export type Sys = {
  __typename: 'Sys';
  environmentId: Scalars['String'];
  firstPublishedAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** The locale that was requested. */
  locale: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  publishedVersion: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id: InputMaybe<Scalars['String']>;
  id_contains: InputMaybe<Scalars['String']>;
  id_exists: InputMaybe<Scalars['Boolean']>;
  id_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not: InputMaybe<Scalars['String']>;
  id_not_contains: InputMaybe<Scalars['String']>;
  id_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte: InputMaybe<Scalars['DateTime']>;
  publishedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte: InputMaybe<Scalars['DateTime']>;
  publishedAt_not: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion: InputMaybe<Scalars['Float']>;
  publishedVersion_exists: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt: InputMaybe<Scalars['Float']>;
  publishedVersion_gte: InputMaybe<Scalars['Float']>;
  publishedVersion_in: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt: InputMaybe<Scalars['Float']>;
  publishedVersion_lte: InputMaybe<Scalars['Float']>;
  publishedVersion_not: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type Table = Entry &
  _Node & {
    __typename: 'Table';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<TableLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    richTableContent: Maybe<TableRichTableContent>;
    sys: Sys;
    tagsCollection: Maybe<TableTagsCollection>;
    title: Maybe<Scalars['String']>;
  };

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type TableInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type TableLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type TableReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type TableRichTableContentArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type TableTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TableTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/table) */
export type TableTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TableCollection = {
  __typename: 'TableCollection';
  items: Array<Maybe<Table>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TableFilter = {
  AND: InputMaybe<Array<InputMaybe<TableFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TableFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  richTableContent_contains: InputMaybe<Scalars['String']>;
  richTableContent_exists: InputMaybe<Scalars['Boolean']>;
  richTableContent_not_contains: InputMaybe<Scalars['String']>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TableLinkingCollections = {
  __typename: 'TableLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type TableLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TableOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TableRichTableContent = {
  __typename: 'TableRichTableContent';
  json: Scalars['JSON'];
  links: TableRichTableContentLinks;
};

export type TableRichTableContentAssets = {
  __typename: 'TableRichTableContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TableRichTableContentEntries = {
  __typename: 'TableRichTableContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TableRichTableContentLinks = {
  __typename: 'TableRichTableContentLinks';
  assets: TableRichTableContentAssets;
  entries: TableRichTableContentEntries;
  resources: TableRichTableContentResources;
};

export type TableRichTableContentResources = {
  __typename: 'TableRichTableContentResources';
  block: Array<TableRichTableContentResourcesBlock>;
  hyperlink: Array<TableRichTableContentResourcesHyperlink>;
  inline: Array<TableRichTableContentResourcesInline>;
};

export type TableRichTableContentResourcesBlock = ResourceLink & {
  __typename: 'TableRichTableContentResourcesBlock';
  sys: ResourceSys;
};

export type TableRichTableContentResourcesHyperlink = ResourceLink & {
  __typename: 'TableRichTableContentResourcesHyperlink';
  sys: ResourceSys;
};

export type TableRichTableContentResourcesInline = ResourceLink & {
  __typename: 'TableRichTableContentResourcesInline';
  sys: ResourceSys;
};

export type TableTagsCollection = {
  __typename: 'TableTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TableTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type Tag = Entry &
  _Node & {
    __typename: 'Tag';
    _id: Scalars['ID'];
    category: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    excludePersonalisationSegmentsCollection: Maybe<TagExcludePersonalisationSegmentsCollection>;
    linkedFrom: Maybe<TagLinkingCollections>;
    name: Maybe<Scalars['String']>;
    personalisationSegmentsCollection: Maybe<TagPersonalisationSegmentsCollection>;
    sys: Sys;
    value: Maybe<Scalars['String']>;
  };

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type TagCategoryArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type TagExcludePersonalisationSegmentsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type TagLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type TagNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type TagPersonalisationSegmentsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/tag) */
export type TagValueArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TagCollection = {
  __typename: 'TagCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TagExcludePersonalisationSegmentsCollection = {
  __typename: 'TagExcludePersonalisationSegmentsCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TagFilter = {
  AND: InputMaybe<Array<InputMaybe<TagFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TagFilter>>>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  excludePersonalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  personalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagLinkingCollections = {
  __typename: 'TagLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  tableCollection: Maybe<TableCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type TagLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsTableCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsTableCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TagLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TagLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsTableCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type TagPersonalisationSegmentsCollection = {
  __typename: 'TagPersonalisationSegmentsCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type Teaser = Entry &
  _Node & {
    __typename: 'Teaser';
    _id: Scalars['ID'];
    backgroundMedia: Maybe<Image>;
    callToActionCollection: Maybe<TeaserCallToActionCollection>;
    contentfulMetadata: ContentfulMetadata;
    embedMedia: Maybe<EmbedMedia>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<TeaserLinkingCollections>;
    primaryMediaImage: Maybe<Image>;
    primaryMediaVideo: Maybe<Video>;
    richPreamble: Maybe<TeaserRichPreamble>;
    sys: Sys;
    tagline: Maybe<Scalars['String']>;
    taglineType: Maybe<Scalars['String']>;
    tagsCollection: Maybe<TeaserTagsCollection>;
    teaserUrl: Maybe<Scalars['String']>;
    title: Maybe<Scalars['String']>;
    uniqueSellingPointsCollection: Maybe<TeaserUniqueSellingPointsCollection>;
  };

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserBackgroundMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserCallToActionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserCallToActionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserEmbedMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<EmbedMediaFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserPrimaryMediaImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserPrimaryMediaVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserRichPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserTaglineArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserTaglineTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserTeaserUrlArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/teaser) */
export type TeaserUniqueSellingPointsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserCallToActionCollection = {
  __typename: 'TeaserCallToActionCollection';
  items: Array<Maybe<CtaButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserCallToActionCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TeaserCollection = {
  __typename: 'TeaserCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TeaserFilter = {
  AND: InputMaybe<Array<InputMaybe<TeaserFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TeaserFilter>>>;
  backgroundMedia: InputMaybe<CfImageNestedFilter>;
  backgroundMedia_exists: InputMaybe<Scalars['Boolean']>;
  callToAction: InputMaybe<CfCtaButtonNestedFilter>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia: InputMaybe<CfEmbedMediaNestedFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryMediaImage: InputMaybe<CfImageNestedFilter>;
  primaryMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  primaryMediaVideo: InputMaybe<CfVideoNestedFilter>;
  primaryMediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_contains: InputMaybe<Scalars['String']>;
  richPreamble_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_not_contains: InputMaybe<Scalars['String']>;
  sys: InputMaybe<SysFilter>;
  tagline: InputMaybe<Scalars['String']>;
  taglineType: InputMaybe<Scalars['String']>;
  taglineType_contains: InputMaybe<Scalars['String']>;
  taglineType_exists: InputMaybe<Scalars['Boolean']>;
  taglineType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taglineType_not: InputMaybe<Scalars['String']>;
  taglineType_not_contains: InputMaybe<Scalars['String']>;
  taglineType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_contains: InputMaybe<Scalars['String']>;
  tagline_exists: InputMaybe<Scalars['Boolean']>;
  tagline_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_not: InputMaybe<Scalars['String']>;
  tagline_not_contains: InputMaybe<Scalars['String']>;
  tagline_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl: InputMaybe<Scalars['String']>;
  teaserUrl_contains: InputMaybe<Scalars['String']>;
  teaserUrl_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teaserUrl_not: InputMaybe<Scalars['String']>;
  teaserUrl_not_contains: InputMaybe<Scalars['String']>;
  teaserUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueSellingPointsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type TeaserLinkingCollections = {
  __typename: 'TeaserLinkingCollections';
  assemblyDamageReportCollection: Maybe<AssemblyDamageReportCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel2Collection: Maybe<AssemblyLevel2Collection>;
  assemblyLevel2FaqCollection: Maybe<AssemblyLevel2FaqCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type TeaserLinkingCollectionsAssemblyDamageReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblyDamageReportCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyLevel0CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyLevel2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyLevel2CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyLevel2FaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyLevel2FaqCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TeaserLinkingCollectionsAssemblyDamageReportCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyLevel2CollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyLevel2FaqCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TeaserOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TeaserRichPreamble = {
  __typename: 'TeaserRichPreamble';
  json: Scalars['JSON'];
  links: TeaserRichPreambleLinks;
};

export type TeaserRichPreambleAssets = {
  __typename: 'TeaserRichPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TeaserRichPreambleEntries = {
  __typename: 'TeaserRichPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TeaserRichPreambleLinks = {
  __typename: 'TeaserRichPreambleLinks';
  assets: TeaserRichPreambleAssets;
  entries: TeaserRichPreambleEntries;
  resources: TeaserRichPreambleResources;
};

export type TeaserRichPreambleResources = {
  __typename: 'TeaserRichPreambleResources';
  block: Array<TeaserRichPreambleResourcesBlock>;
  hyperlink: Array<TeaserRichPreambleResourcesHyperlink>;
  inline: Array<TeaserRichPreambleResourcesInline>;
};

export type TeaserRichPreambleResourcesBlock = ResourceLink & {
  __typename: 'TeaserRichPreambleResourcesBlock';
  sys: ResourceSys;
};

export type TeaserRichPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'TeaserRichPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type TeaserRichPreambleResourcesInline = ResourceLink & {
  __typename: 'TeaserRichPreambleResourcesInline';
  sys: ResourceSys;
};

export type TeaserTagsCollection = {
  __typename: 'TeaserTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type TeaserUniqueSellingPointsCollection = {
  __typename: 'TeaserUniqueSellingPointsCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type Video = Entry &
  _Node & {
    __typename: 'Video';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    copyrightLogo: Maybe<Image>;
    copyrightText: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<VideoLinkingCollections>;
    posterImage: Maybe<Image>;
    sys: Sys;
    video: Maybe<Asset>;
  };

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type VideoCopyrightLogoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type VideoCopyrightTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type VideoInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type VideoLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type VideoPosterImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/mqsajv7w4681/content_types/video) */
export type VideoVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type VideoCollection = {
  __typename: 'VideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VideoFilter = {
  AND: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  OR: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightLogo: InputMaybe<CfImageNestedFilter>;
  copyrightLogo_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  posterImage: InputMaybe<CfImageNestedFilter>;
  posterImage_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  video_exists: InputMaybe<Scalars['Boolean']>;
};

export type VideoLinkingCollections = {
  __typename: 'VideoLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  ctaButtonCollection: Maybe<CtaButtonCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type VideoLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsCtaButtonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsCtaButtonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum VideoLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum VideoLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum VideoLinkingCollectionsCtaButtonCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum VideoLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum VideoLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum VideoOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type _Node = {
  _id: Scalars['ID'];
};

export type CfArticleNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfArticleNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfArticleNestedFilter>>>;
  authorCollection_exists: InputMaybe<Scalars['Boolean']>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  factBoxCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationDate: InputMaybe<Scalars['DateTime']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']>;
  publicationDate_gt: InputMaybe<Scalars['DateTime']>;
  publicationDate_gte: InputMaybe<Scalars['DateTime']>;
  publicationDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publicationDate_lt: InputMaybe<Scalars['DateTime']>;
  publicationDate_lte: InputMaybe<Scalars['DateTime']>;
  publicationDate_not: InputMaybe<Scalars['DateTime']>;
  publicationDate_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAssemblyLevel2NestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfAssemblyLevel2NestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfAssemblyLevel2NestedFilter>>>;
  article_exists: InputMaybe<Scalars['Boolean']>;
  cardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  cardsTitle: InputMaybe<Scalars['String']>;
  cardsTitle_contains: InputMaybe<Scalars['String']>;
  cardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  cardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cardsTitle_not: InputMaybe<Scalars['String']>;
  cardsTitle_not_contains: InputMaybe<Scalars['String']>;
  cardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSectionsCollection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfCtaButtonNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfCtaButtonNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfCtaButtonNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  ctaText: InputMaybe<Scalars['String']>;
  ctaText_contains: InputMaybe<Scalars['String']>;
  ctaText_exists: InputMaybe<Scalars['Boolean']>;
  ctaText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaText_not: InputMaybe<Scalars['String']>;
  ctaText_not_contains: InputMaybe<Scalars['String']>;
  ctaText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType: InputMaybe<Scalars['String']>;
  ctaType_contains: InputMaybe<Scalars['String']>;
  ctaType_exists: InputMaybe<Scalars['Boolean']>;
  ctaType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType_not: InputMaybe<Scalars['String']>;
  ctaType_not_contains: InputMaybe<Scalars['String']>;
  ctaType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageTrigger_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger: InputMaybe<Scalars['String']>;
  linkTrigger_contains: InputMaybe<Scalars['String']>;
  linkTrigger_exists: InputMaybe<Scalars['Boolean']>;
  linkTrigger_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger_not: InputMaybe<Scalars['String']>;
  linkTrigger_not_contains: InputMaybe<Scalars['String']>;
  linkTrigger_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  videoTrigger_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfEmbedMediaNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfEmbedMediaNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfEmbedMediaNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  url: InputMaybe<Scalars['String']>;
  url_contains: InputMaybe<Scalars['String']>;
  url_exists: InputMaybe<Scalars['Boolean']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not: InputMaybe<Scalars['String']>;
  url_not_contains: InputMaybe<Scalars['String']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFactBoxNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfFactBoxNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfFactBoxNestedFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaAssetsCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqItemNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfFaqItemNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfFaqItemNestedFilter>>>;
  answer: InputMaybe<Scalars['String']>;
  answer_contains: InputMaybe<Scalars['String']>;
  answer_exists: InputMaybe<Scalars['Boolean']>;
  answer_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  answer_not: InputMaybe<Scalars['String']>;
  answer_not_contains: InputMaybe<Scalars['String']>;
  answer_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  question: InputMaybe<Scalars['String']>;
  question_contains: InputMaybe<Scalars['String']>;
  question_exists: InputMaybe<Scalars['Boolean']>;
  question_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  question_not: InputMaybe<Scalars['String']>;
  question_not_contains: InputMaybe<Scalars['String']>;
  question_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfFaqSectionNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfFaqSectionNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfFaqSectionNestedFilter>>>;
  backgroundTitle_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSectionCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle: InputMaybe<Scalars['String']>;
  sectionTitle_contains: InputMaybe<Scalars['String']>;
  sectionTitle_exists: InputMaybe<Scalars['Boolean']>;
  sectionTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle_not: InputMaybe<Scalars['String']>;
  sectionTitle_not_contains: InputMaybe<Scalars['String']>;
  sectionTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfImageNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfImageNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfImageNestedFilter>>>;
  altText: InputMaybe<Scalars['String']>;
  altText_contains: InputMaybe<Scalars['String']>;
  altText_exists: InputMaybe<Scalars['Boolean']>;
  altText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  altText_not: InputMaybe<Scalars['String']>;
  altText_not_contains: InputMaybe<Scalars['String']>;
  altText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightImg_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea: InputMaybe<Scalars['String']>;
  focusArea_contains: InputMaybe<Scalars['String']>;
  focusArea_exists: InputMaybe<Scalars['Boolean']>;
  focusArea_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea_not: InputMaybe<Scalars['String']>;
  focusArea_not_contains: InputMaybe<Scalars['String']>;
  focusArea_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink: InputMaybe<Scalars['String']>;
  hyperlink_contains: InputMaybe<Scalars['String']>;
  hyperlink_exists: InputMaybe<Scalars['Boolean']>;
  hyperlink_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink_not: InputMaybe<Scalars['String']>;
  hyperlink_not_contains: InputMaybe<Scalars['String']>;
  hyperlink_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfResourceNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfResourceNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfResourceNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key: InputMaybe<Scalars['String']>;
  key_contains: InputMaybe<Scalars['String']>;
  key_exists: InputMaybe<Scalars['Boolean']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not: InputMaybe<Scalars['String']>;
  key_not_contains: InputMaybe<Scalars['String']>;
  key_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSeoNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists: InputMaybe<Scalars['Boolean']>;
  no_follow: InputMaybe<Scalars['Boolean']>;
  no_follow_exists: InputMaybe<Scalars['Boolean']>;
  no_follow_not: InputMaybe<Scalars['Boolean']>;
  no_index: InputMaybe<Scalars['Boolean']>;
  no_index_exists: InputMaybe<Scalars['Boolean']>;
  no_index_not: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription: InputMaybe<Scalars['String']>;
  socialMediaDescription_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaDescription_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription_not: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName: InputMaybe<Scalars['String']>;
  socialMediaSiteName_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaSiteName_not: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle: InputMaybe<Scalars['String']>;
  socialMediaTitle_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle_not: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xRobotsTag: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_exists: InputMaybe<Scalars['Boolean']>;
  xRobotsTag_gt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_gte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  xRobotsTag_lt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_lte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type CfTagNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  excludePersonalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  personalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTeaserNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfTeaserNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfTeaserNestedFilter>>>;
  backgroundMedia_exists: InputMaybe<Scalars['Boolean']>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  primaryMediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_contains: InputMaybe<Scalars['String']>;
  richPreamble_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_not_contains: InputMaybe<Scalars['String']>;
  sys: InputMaybe<SysFilter>;
  tagline: InputMaybe<Scalars['String']>;
  taglineType: InputMaybe<Scalars['String']>;
  taglineType_contains: InputMaybe<Scalars['String']>;
  taglineType_exists: InputMaybe<Scalars['Boolean']>;
  taglineType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taglineType_not: InputMaybe<Scalars['String']>;
  taglineType_not_contains: InputMaybe<Scalars['String']>;
  taglineType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_contains: InputMaybe<Scalars['String']>;
  tagline_exists: InputMaybe<Scalars['Boolean']>;
  tagline_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_not: InputMaybe<Scalars['String']>;
  tagline_not_contains: InputMaybe<Scalars['String']>;
  tagline_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl: InputMaybe<Scalars['String']>;
  teaserUrl_contains: InputMaybe<Scalars['String']>;
  teaserUrl_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teaserUrl_not: InputMaybe<Scalars['String']>;
  teaserUrl_not_contains: InputMaybe<Scalars['String']>;
  teaserUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueSellingPointsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfVideoNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightLogo_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  posterImage_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  video_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfbottomCardItemsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfbottomCardItemsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfbottomCardItemsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export type CfcardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfcardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfcardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export type CftopCardItemsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CftopCardItemsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CftopCardItemsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export type ArticleFragment = {
  __typename: 'Article';
  readableId: string;
  title: string;
  category: string;
  preamble: string;
  mediaImageCollection: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      image: { __typename: 'Asset'; url: string };
    }>;
  };
  mediaVideoCollection: {
    __typename: 'ArticleMediaVideoCollection';
    items: Array<{
      __typename: 'Video';
      video: { __typename: 'Asset'; url: string };
      posterImage: {
        __typename: 'Image';
        image: { __typename: 'Asset'; url: string };
      };
    }>;
  };
  body: ({ __typename: 'ArticleBody' } & ArticleBodyFragment);
  tagsCollection: {
    __typename: 'ArticleTagsCollection';
    items: Array<{ __typename: 'Tag'; value: string }>;
  };
  seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
};

export type GetArticleBySlugQueryVariables = Exact<{
  slug: InputMaybe<Scalars['String']>;
}>;

export type GetArticleBySlugQuery = {
  __typename: 'Query';
  data: {
    __typename: 'ArticleAssemblyCollection';
    items: Array<{
      __typename: 'ArticleAssembly';
      internalName: string;
      slug: string;
      article: ({ __typename: 'Article' } & ArticleFragment);
      faqSection: ({ __typename: 'FaqSection' } & FaqSectionFragment);
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type ArticleBodyFragment = {
  __typename: 'ArticleBody';
  json: any;
  links: {
    __typename: 'ArticleBodyLinks';
    entries: {
      __typename: 'ArticleBodyEntries';
      block: Array<
        | { __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'ArticleAssembly'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyDamageReport'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel0'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel2'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel2Faq'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Campaign'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'CtaButton'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'EmbedMedia';
            url: string;
            internalName: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'FactBox'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FactBoxWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FaqItem'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FaqSection'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Image';
            hyperlink: string | null;
            image: { __typename: 'Asset'; title: string; url: string };
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'Resource'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'ResourceSet'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Seo'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Table'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Teaser'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Video';
            video: { __typename: 'Asset'; title: string; url: string };
            sys: { __typename: 'Sys'; id: string };
          }
       
      >;
    };
    assets: {
      __typename: 'ArticleBodyAssets';
      hyperlink: Array<{
        __typename: 'Asset';
        contentType: string;
        title: string;
        url: string;
        sys: { __typename: 'Sys'; id: string };
      }>;
    };
  };
};

export type CardArticleAssemblyFragment = {
  __typename: 'ArticleAssembly';
  article: {
    __typename: 'Article';
    slug: string;
    title: string;
    preamble: string;
    mediaImageCollection: {
      __typename: 'ArticleMediaImageCollection';
      items: Array<{
        __typename: 'Image';
        altText: string;
        image: { __typename: 'Asset'; url: string };
      }>;
    };
  };
};

export type CardAssemblyLevel2Fragment = {
  __typename: 'AssemblyLevel2';
  slug: string;
  heroBanner: {
    __typename: 'Teaser';
    title: string;
    richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
    primaryMediaImage: {
      __typename: 'Image';
      altText: string;
      image: { __typename: 'Asset'; url: string };
    };
  };
};

export type CardAssemblyLevel2FaqFragment = {
  __typename: 'AssemblyLevel2Faq';
  slug: string;
  heroBanner: {
    __typename: 'Teaser';
    title: string;
    richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
    primaryMediaImage: {
      __typename: 'Image';
      altText: string;
      image: { __typename: 'Asset'; url: string };
    };
  };
};

export type CardTeaserFragment = {
  __typename: 'Teaser';
  title: string;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    image: { __typename: 'Asset'; url: string };
  };
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{
      __typename: 'CtaButton';
      ctaText: string;
      linkTrigger: string;
    }>;
  };
};

export type GetDamageReportCollectionQueryVariables = Exact<{
  slug: InputMaybe<Scalars['String']>;
}>;

export type GetDamageReportCollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyDamageReportCollection';
    items: Array<{
      __typename: 'AssemblyDamageReport';
      slug: string;
      heroBanner: ({ __typename: 'Teaser' } & AssemblyLevel0HeroBannerFragment);
      card: ({ __typename: 'Teaser' } & AssemblyLevel0HeroBannerFragment);
      faqSection: ({ __typename: 'FaqSection' } & FaqSectionFragment);
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type FaqSectionFragment = {
  __typename: 'FaqSection';
  sectionTitle: string;
  faqSectionCollection: {
    __typename: 'FaqSectionFaqSectionCollection';
    items: Array<{
      __typename: 'FaqItem';
      question: string;
      answer: string;
    }>;
  };
};

export type ImageFieldsFragment = {
  __typename: 'Image';
  focusArea: string;
  altText: string;
  image: { __typename: 'Asset'; url: string };
  contentfulMetadata: {
    __typename: 'ContentfulMetadata';
    tags: Array<{ __typename: 'ContentfulTag'; name: string }>;
  };
};

export type GetAssemblyLevel0CollectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssemblyLevel0CollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyLevel0Collection';
    items: Array<{
      __typename: 'AssemblyLevel0';
      bottomCardsHeading: string;
      heroBanner: ({ __typename: 'Teaser' } & AssemblyLevel0HeroBannerFragment);
      infoBox: ({ __typename: 'Teaser' } & AssemblyLevel0InfoBoxFragment);
      topCardsDescription: ({ __typename: 'Teaser' } & AssemblyLevel0InfoBoxFragment);
      topCardItemsCollection: {
        __typename: 'AssemblyLevel0TopCardItemsCollection';
        items: Array<
          | ({ __typename: 'ArticleAssembly' } & CardArticleAssemblyFragment)
          | ({ __typename: 'AssemblyLevel2' } & CardAssemblyLevel2Fragment)
          | ({ __typename: 'AssemblyLevel2Faq' } & CardAssemblyLevel2FaqFragment)
          | ({ __typename: 'Teaser' } & CardTeaserFragment)
         
        >;
      };
      bottomCardItemsCollection: {
        __typename: 'AssemblyLevel0BottomCardItemsCollection';
        items: Array<
          | ({ __typename: 'ArticleAssembly' } & CardArticleAssemblyFragment)
          | ({ __typename: 'AssemblyLevel2' } & CardAssemblyLevel2Fragment)
          | ({ __typename: 'AssemblyLevel2Faq' } & CardAssemblyLevel2FaqFragment)
          | ({ __typename: 'Teaser' } & CardTeaserFragment)
         
        >;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type AssemblyLevel0HeroBannerFragment = {
  __typename: 'Teaser';
  title: string;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    image: { __typename: 'Asset'; url: string };
  };
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
  primaryMediaVideo: {
    __typename: 'Video';
    video: { __typename: 'Asset'; url: string };
  };
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type AssemblyLevel0InfoBoxFragment = {
  __typename: 'Teaser';
  title: string;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
  primaryMediaImage: {
    __typename: 'Image';
    image: { __typename: 'Asset'; url: string };
  };
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type GetAssemblyLevel2CollectionQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetAssemblyLevel2CollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyLevel2Collection';
    items: Array<{
      __typename: 'AssemblyLevel2';
      slug: string;
      cardsTitle: string;
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          image: { __typename: 'Asset'; url: string };
        };
      };
      article:
        | ({
            __typename: 'Article';
            factBoxCollection: {
              __typename: 'ArticleFactBoxCollection';
              items: Array<{
                __typename: 'FactBox';
                title: string;
                body: { __typename: 'FactBoxBody'; json: any };
                mediaImage: {
                  __typename: 'Image';
                  altText: string;
                  image: { __typename: 'Asset'; url: string };
                };
              }>;
            };
          } & ArticleFragment)
       ;
      cardsCollection: {
        __typename: 'AssemblyLevel2CardsCollection';
        items: Array<
          | ({ __typename: 'ArticleAssembly' } & CardArticleAssemblyFragment)
          | ({ __typename: 'AssemblyLevel2' } & CardAssemblyLevel2Fragment)
          | ({ __typename: 'AssemblyLevel2Faq' } & CardAssemblyLevel2FaqFragment)
          | ({ __typename: 'Teaser' } & CardTeaserFragment)
         
        >;
      };
      faqSectionsCollection: {
        __typename: 'AssemblyLevel2FaqSectionsCollection';
        items: Array<({ __typename: 'FaqSection' } & FaqSectionFragment)>;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetAssemblyLevel2FaqCollectionQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetAssemblyLevel2FaqCollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyLevel2FaqCollection';
    items: Array<{
      __typename: 'AssemblyLevel2Faq';
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          image: { __typename: 'Asset'; url: string };
        };
        primaryMediaVideo: {
          __typename: 'Video';
          video: { __typename: 'Asset'; url: string };
        };
      };
      faqSectionsCollection: {
        __typename: 'AssemblyLevel2FaqFaqSectionsCollection';
        items: Array<({ __typename: 'FaqSection' } & FaqSectionFragment)>;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type SeoMetadataFragment = {
  __typename: 'Seo';
  title: string;
  description: string;
  keywords: Array<string>;
  noIndex: boolean;
  noFollow: boolean;
  xRobotsTag: any;
  socialMediaSiteName: string;
  socialMediaTitle: string;
  socialMediaDescription: string;
  socialMediaImage: {
    __typename: 'Image';
    image: { __typename: 'Asset'; url: string };
  };
};

export type GetThankYouPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetThankYouPageQuery = {
  __typename: 'Query';
  data: {
    __typename: 'FactBoxCollection';
    items: Array<{
      __typename: 'FactBox';
      title: string;
      body: { __typename: 'FactBoxBody'; json: any };
      callToActionCollection: {
        __typename: 'FactBoxCallToActionCollection';
        items: Array<{
          __typename: 'CtaButton';
          ctaText: string;
          linkTrigger: string;
        }>;
      };
    }>;
  };
};

export const ArticleBodyFragmentDoc = gql`
  fragment ArticleBody on ArticleBody {
    json
    links {
      entries {
        block {
          sys {
            id
          }
          __typename
          ... on Image {
            image {
              title
              url(transform: { format: WEBP })
            }
            hyperlink
          }
          ... on Video {
            video {
              title
              url
            }
          }
          ... on EmbedMedia {
            url
            internalName
          }
        }
      }
      assets {
        hyperlink {
          sys {
            id
          }
          contentType
          title
          url
        }
      }
    }
  }
`;
export const SeoMetadataFragmentDoc = gql`
  fragment SeoMetadata on Seo {
    title
    description
    keywords
    noIndex
    noFollow
    xRobotsTag
    socialMediaSiteName
    socialMediaTitle
    socialMediaDescription
    socialMediaImage {
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const ArticleFragmentDoc = gql`
  fragment Article on Article {
    readableId
    title
    category
    preamble
    mediaImageCollection(limit: 1) {
      items {
        image {
          url(transform: { format: WEBP })
        }
      }
    }
    mediaVideoCollection(limit: 1) {
      items {
        video {
          url
        }
        posterImage {
          image {
            url(transform: { format: WEBP })
          }
        }
      }
    }
    body {
      ...ArticleBody
    }
    tagsCollection {
      items {
        value
      }
    }
    seoMetadata {
      ...SeoMetadata
    }
  }
`;
export const CardArticleAssemblyFragmentDoc = gql`
  fragment CardArticleAssembly on ArticleAssembly {
    article {
      slug
      title
      preamble
      mediaImageCollection(limit: 1) {
        items {
          altText
          image {
            url(transform: { format: WEBP })
          }
        }
      }
    }
  }
`;
export const CardAssemblyLevel2FragmentDoc = gql`
  fragment CardAssemblyLevel2 on AssemblyLevel2 {
    slug
    heroBanner {
      title
      richPreamble {
        json
      }
      primaryMediaImage {
        altText
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const CardAssemblyLevel2FaqFragmentDoc = gql`
  fragment CardAssemblyLevel2Faq on AssemblyLevel2Faq {
    slug
    heroBanner {
      title
      richPreamble {
        json
      }
      primaryMediaImage {
        altText
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const CardTeaserFragmentDoc = gql`
  fragment CardTeaser on Teaser {
    title
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      image {
        url(transform: { format: WEBP })
      }
    }
    callToActionCollection(limit: 1) {
      items {
        ctaText
        linkTrigger
      }
    }
  }
`;
export const FaqSectionFragmentDoc = gql`
  fragment FaqSection on FaqSection {
    sectionTitle
    faqSectionCollection {
      items {
        question
        answer
      }
    }
  }
`;
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on Image {
    focusArea
    altText
    image {
      url(transform: { format: WEBP })
    }
    contentfulMetadata {
      tags {
        name
      }
    }
  }
`;
export const AssemblyLevel0HeroBannerFragmentDoc = gql`
  fragment AssemblyLevel0HeroBanner on Teaser {
    title
    primaryMediaImage {
      altText
      image {
        url(transform: { format: WEBP })
      }
    }
    richPreamble {
      json
    }
    primaryMediaVideo {
      video {
        url
      }
    }
    callToActionCollection(limit: 1) {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const AssemblyLevel0InfoBoxFragmentDoc = gql`
  fragment AssemblyLevel0InfoBox on Teaser {
    title
    richPreamble {
      json
    }
    primaryMediaImage {
      image {
        url(transform: { format: WEBP })
      }
    }
    callToActionCollection(limit: 1) {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const GetArticleBySlugDocument = gql`
  query GetArticleBySlug($slug: String) {
    data: articleAssemblyCollection(limit: 1, where: { slug: $slug }) {
      items {
        internalName
        slug
        article {
          ...Article
        }
        faqSection {
          ...FaqSection
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${ArticleFragmentDoc}
  ${ArticleBodyFragmentDoc}
  ${SeoMetadataFragmentDoc}
  ${FaqSectionFragmentDoc}
`;
export const GetDamageReportCollectionDocument = gql`
  query GetDamageReportCollection($slug: String) {
    data: assemblyDamageReportCollection(limit: 1, where: { slug: $slug }) {
      items {
        slug
        heroBanner {
          ... on Teaser {
            ...AssemblyLevel0HeroBanner
          }
        }
        card {
          ... on Teaser {
            ...AssemblyLevel0HeroBanner
          }
        }
        faqSection {
          ...FaqSection
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${AssemblyLevel0HeroBannerFragmentDoc}
  ${FaqSectionFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetAssemblyLevel0CollectionDocument = gql`
  query GetAssemblyLevel0Collection {
    data: assemblyLevel0Collection(limit: 1) {
      items {
        heroBanner {
          ... on Teaser {
            ...AssemblyLevel0HeroBanner
          }
        }
        infoBox {
          ... on Teaser {
            ...AssemblyLevel0InfoBox
          }
        }
        topCardsDescription {
          ... on Teaser {
            ...AssemblyLevel0InfoBox
          }
        }
        topCardItemsCollection {
          items {
            ... on Teaser {
              ...CardTeaser
            }
            ... on ArticleAssembly {
              ...CardArticleAssembly
            }
            ... on AssemblyLevel2Faq {
              ...CardAssemblyLevel2Faq
            }
            ... on AssemblyLevel2 {
              ...CardAssemblyLevel2
            }
          }
        }
        bottomCardsHeading
        bottomCardItemsCollection {
          items {
            ... on Teaser {
              ...CardTeaser
            }
            ... on ArticleAssembly {
              ...CardArticleAssembly
            }
            ... on AssemblyLevel2Faq {
              ...CardAssemblyLevel2Faq
            }
            ... on AssemblyLevel2 {
              ...CardAssemblyLevel2
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${AssemblyLevel0HeroBannerFragmentDoc}
  ${AssemblyLevel0InfoBoxFragmentDoc}
  ${CardTeaserFragmentDoc}
  ${CardArticleAssemblyFragmentDoc}
  ${CardAssemblyLevel2FaqFragmentDoc}
  ${CardAssemblyLevel2FragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetAssemblyLevel2CollectionDocument = gql`
  query GetAssemblyLevel2Collection($slug: String!) {
    data: assemblyLevel2Collection(limit: 1, where: { slug: $slug }) {
      items {
        slug
        heroBanner {
          ... on Teaser {
            title
            richPreamble {
              json
            }
            primaryMediaImage {
              altText
              image {
                url(transform: { format: WEBP })
              }
            }
          }
        }
        article {
          ...Article
          factBoxCollection(limit: 4) {
            items {
              title
              body {
                json
              }
              mediaImage {
                altText
                image {
                  url(transform: { format: WEBP })
                }
              }
            }
          }
        }
        cardsTitle
        cardsCollection {
          items {
            ... on ArticleAssembly {
              ...CardArticleAssembly
            }
            ... on AssemblyLevel2 {
              ...CardAssemblyLevel2
            }
            ... on AssemblyLevel2Faq {
              ...CardAssemblyLevel2Faq
            }
            ... on Teaser {
              ...CardTeaser
            }
          }
        }
        faqSectionsCollection {
          items {
            ...FaqSection
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${ArticleFragmentDoc}
  ${ArticleBodyFragmentDoc}
  ${SeoMetadataFragmentDoc}
  ${CardArticleAssemblyFragmentDoc}
  ${CardAssemblyLevel2FragmentDoc}
  ${CardAssemblyLevel2FaqFragmentDoc}
  ${CardTeaserFragmentDoc}
  ${FaqSectionFragmentDoc}
`;
export const GetAssemblyLevel2FaqCollectionDocument = gql`
  query GetAssemblyLevel2FaqCollection($slug: String!) {
    data: assemblyLevel2FaqCollection(limit: 1, where: { slug: $slug }) {
      items {
        heroBanner {
          ... on Teaser {
            title
            richPreamble {
              json
            }
            primaryMediaImage {
              altText
              image {
                url
              }
            }
            primaryMediaVideo {
              video {
                url
              }
            }
          }
        }
        faqSectionsCollection {
          items {
            ...FaqSection
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${FaqSectionFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetThankYouPageDocument = gql`
  query GetThankYouPage {
    data: factBoxCollection(limit: 1, where: { readableId: "thank-you-page" }) {
      items {
        title
        body {
          json
        }
        callToActionCollection(limit: 1) {
          items {
            ctaText
            linkTrigger
          }
        }
      }
    }
  }
`;
