export enum DamageCategoryOptions {
  GROUND_CABLE = 'damage_report_form.step_one.category_ground',
  AIR_CABLE = 'damage_report_form.step_one.category_air',
  CABINET = 'damage_report_form.step_one.category_cabinet',
  TECH_BUILDING = 'damage_report_form.step_one.category_tech_building',
  POLE = 'damage_report_form.step_one.category_post',
  WELL = 'damage_report_form.step_one.category_well',
}

export enum GuideCorrectOptions {
  YES = 'damage_report_form.step_one.yes',
  NO = 'damage_report_form.step_one.no',
  UNKNOWN = 'damage_report_form.step_one.do_not_know',
}

export enum FiberOptions {
  FIBER_IN_SEWER = 'damage_report_form.step_one.fiber_in_sewer',
  SEWER_ONLY = 'damage_report_form.step_one.sewer_only',
}

export enum CopperOptions {
  PLASTIC_SHEATHED = 'damage_report_form.step_one.plastic_sheathed',
  LEAD_SHEATHED = 'damage_report_form.step_one.lead_sheathed',
  ABOVE_CHANNELING = 'damage_report_form.step_one.above_channeling',
}
